.App {
   /*padding: 20px;*/
}

.topSpacing{
   margin-top: 40px;
}
.bottomSpacing{
   margin-bottom: 40px;
}
.breathe{
   margin-top: 40px;
   margin-bottom: 40px;
}
.noBGRepeat{
   background-repeat: no-repeat;
}

.itemBox{
   display: flex;
   flex-direction: column;
   align-items: baseline;
   justify-content: end;
   min-height: 500px;
   padding: 1rem;
   border-radius: 25px;
   background-repeat: no-repeat;
   background-size: cover;
   -webkit-justify-content: flex-end;
}

.coverPhotoParent{
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 40vh;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
}

.globalMargin{
   margin-left: 40px;
   margin-right: 40px;
}
.hideSB::-webkit-scrollbar{
   opacity: 0;
   cursor: grab;
}

@media only screen and (max-width: 600px) {
   /* For mobile phones: */
  .globalMargin{
     margin-left: 10px;
     margin-right: 10px;
  }
   .breathe{
      margin-top: 20px;
      margin-bottom: 20px;
   }
}

.appBody{
   padding: 20px;
}

a {
   all: unset;
}



/* custom scrollbar */
::-webkit-scrollbar {
   width: 20px;
}

::-webkit-scrollbar-track {
   background-color: transparent;
}

::-webkit-scrollbar-thumb {
   background-color: #d6dee1;
   border-radius: 20px;
   border: 6px solid transparent;
   background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
   background-color: #a8bbbf;
}